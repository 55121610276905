/*---------------------------------------------- header css start ----------------------------------------------*/
:root {
  --blue: #005da1;
}

.mobile_menu_btn {
  display: none;
}
.desktop_menu {
  padding-top: 16px;
}
/* header {
  float: left;
  width: 100%;
  background-color: #fff;
  padding: 30px 0;
} */
header {
  float: left;
  width: 100%;
  background-color: #fff;
  padding: 25px 0 20px 0px;
}
/* header .main_logo img {
  float: left;
  width: 100%;
  margin: -3px 0 0 0;
} */
header .main_logo img {
  float: left;
  width: 193px;
  margin: -6px 0 0 -13px;
}
header nav {
  float: left;
  width: 100%;
  height: 51px;
  border-left: #969696 1px solid;
  margin: 0 0 0 0;
}
header nav ul {
  float: left;
  margin: 0px;
}
header nav ul li {
  float: left;
}
header nav ul li a {
  float: left;
  color: #323232;
  text-decoration: none;
  padding: 0 10px;
  letter-spacing: 1px;
}
.header_right_side {
  float: right;
  margin-top: 4px;
}
.header_right_profile {
  float: left;
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #f2f3f7;
}
.header_right_profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_right_notification {
  float: left;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #f2f3f7;
  margin: 0 20px 0 0;
  text-align: center;
}
.header_right_notification i {
  font-size: 20px;
  color: #323232;
  font-weight: bold;
  margin: 13px 0 0;
}
.bm-menu {
  background-color: #fff !important;
  width: 100% !important;
}
.menu-item {
  font-size: 22px !important;
  color: #000 !important;
  padding: 22px 0;
  text-align: center !important;
}
.notification-icon {
  width: 100%;
  padding: 13px;
}
.bm-menu-wrap {
  width: 100% !important;
  left: 0px !important;
}
footer {
  float: left;
  width: 100%;
  background-color: #0f2d3b;
  padding: 30px 0;
}
footer ul {
  float: left;
  width: 100%;
  margin: 0px;
}
footer ul li {
  float: left;
  width: 100%;
  margin: 0px;
}
footer ul li a {
  float: left;
  width: 100%;
  margin: 0px;
  color: #fff;
  text-decoration: none;
  padding: 10px 0;
}
footer ul li a:hover {
  color: #fff !important;
}
footer p {
  float: left;
  width: 100%;
  margin: 0px;
  color: #fff;
}

.scnd_footer {
  float: left;
  width: 100%;
  font-size: 13px;
  background-color: #0b222c;
}
.scnd_footer p {
  color: #fff;
  margin: 0px;
  padding: 20px 0;
  letter-spacing: 1px;
}
.scnd_footer span a {
  color: #323232;
  margin: 0px 20px 0px 0px;
  padding: 20px 0;
  letter-spacing: 1px;
  cursor: pointer;
}
.scnd_footer span a:hover {
  color: #0056b3;
}
.footer_desc {
  margin-top: 11px;
}
.brder_btn {
  border: 1px solid var(--blue);
  background-color: transparent;
  color: var(--blue);
  padding: 8px 20px;
  border-radius: 5px;
}
.btn_login {
  padding: 5px 20px !important;
}
.register-btn {
  z-index: 111 !important;
}
.header-btn {
  z-index: 111 !important;
}
.header-btn .blue_fill_btn {
  float: right !important;
  margin-left: 10px;
}
.header-text{
  display: flex;
  justify-content: flex-end;
}
.text{
  width: 58%;
  margin-right: 20px;
}
/*---------------------------------------------- header css end ----------------------------------------------*/

@media only screen and (max-width: 768px) {
  .bm-menu-wrap {
    width: 100% !important;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .bm-menu-wrap {
    width: 100% !important;
    padding-right: 10px;
  }
  .login_as_coach_btn {
    padding: 4px 6px !important;
    float: left !important;
    width: fit-content !important;
    margin-right: 10px !important;
  }
  .register_btn {
    float: right !important;
    padding: 4px 6px !important;
    width: fit-content !important;
    margin: 0px !important;
  }
  .footer_desc {
    padding: 0px 30px;
  }
}
