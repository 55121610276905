.about-us-container {
  width: 100%;
}
.about-us-container .section-1 {
  width: 100%;
  /* width: 75%; */
  float: left;
  padding: 0 100px;
}
.about-us-container .section-2 {
  width: 25%;
  float: left;
  overflow: hidden;
}
.section-2 img {
  height: 700px;
  width: 100%;
  object-fit: cover;
}
.contact_us_scnd_sec {
  float: left;
  width: 100%;
}
.contact_us_scnd_sec {
  float: left;
  width: 100%;
}
.contact_us_scnd_sec .main_illustration img {
  width: 80%;
}
.contact_us_scnd_sec .secnd_title {
  border-bottom: 1px solid #c1c1c1;
  padding: 0 0 10px 0;
}
.contact_us_scnd_sec input {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.contact_us_scnd_sec textarea {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
  height: 100px;
}
.contact_us_scnd_sec a {
  color: #969696;
  text-decoration: none;
}

.contact_us_third_sec {
  float: left;
  width: 100%;
}
