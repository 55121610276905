.bs-example {
  margin: 20px;
}
.color12 {
  color: #646464 !important;
  font-weight: 300;
}
/* Style to change separator  */
.Tg_calander {
  position: relative;
  bottom: 30px;
  float: right;
  right: 10px;
  font-size: 21px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}
.breadcrumb-item a {
  color: #7f8082;
  text-decoration: none;
}
li.breadcrumb-item.active {
  color: #000000;
}
li.breadcrumb-item.active a {
  font-weight: 600;
  color: #000000;
}
.breadcrumb-item a:hover {
  color: #000000;
}
.btn-cutome {
  border: 1px solid var(--blue);
  background-color: transparent;
  color: var(--blue);
  padding: 6px 65px !important;
  border-radius: 5px;
  float: left !important;
}
.btn-no {
  border: 1px solid var(--blue) !important;
  background-color: transparent !important;
  color: var(--blue) !important;
  padding: 6px 65px !important;
  border-radius: 5px;
  float: right !important;
}
.btn-yes {
  border: 1px solid var(--blue) !important;
  background-color: var(--blue) !important;
  color: #fff !important;
  padding: 6px 65px !important;
  border-radius: 5px;
  float: left !important;
}
.modal-dialog_custome {
  max-width: 590px !important;
  margin: 1.75rem auto;
}
.modal_bgColor {
  background-color: #f2f3f7 !important;
}

.attendance_modal {
  border-radius: 1rem !important;
}
.attendance_modal_custome {
  max-width: 480px !important;
}
.attendance_modal_custome .modal-content {
  border-radius: 0.7rem;
}
.attendance_modal .modal-content {
  border-radius: 0.7rem;
}
.cutome-margin {
  margin-right: 15px;
}
.setup_edu_detail {
  float: left;
  width: 100%;
}
.setup_edu_detail .secnd_title {
  border-bottom: 1px solid #c1c1c1;
  padding: 0 0 10px 0;
}

.setup_edu_detail input {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.setup_edu_detail textarea {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.setup_edu_detail select {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.setup_edu_detail .form-select {
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  color: #959595;
  cursor: pointer;
  /* background-image: url('../img/down-arrow.svg'); */
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 97% center;
}
.setup_edu_detail .question {
  display: none;
}
.setup_edu_detail .active {
  display: block;
}
.stu_att_modal {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 12px;
}
.cust_mr {
  margin-right: 10px !important;
}
.stu_att_modal p {
  font-size: 16px;
}

.stu_att_img img {
  width: 40px;
  height: 40px;
  /* border: 2px solid; */
  border-radius: 50%;
}
.stu_name {
  margin-left: 20px;
  font-size: 16px;
}
.custom-control {
  margin-bottom: 1rem;
  cursor: pointer !important;
}

/* Checkbox */
.stu_att_modal input[type="checkbox"] {
  cursor: pointer;
}
.stu_att_modal input[type="checkbox"] {
  width: 20px;
  height: 17px;
  vertical-align: middle;
  margin-right: 0.2rem;
  margin-bottom: 10px;
  transition: 0.2s;
}
.stu_att_modal input[type="checkbox"]:checked {
  content: "\2714";
  /* width: 20px;
  height: 17px;
  vertical-align: middle;
  margin-right: 0.2rem;
  margin-bottom: 10px; */
}
.stu_att_modal input[type="checkbox"] + label:active:before {
  transform: scale(0);
}
.stu_att_modal label {
  vertical-align: middle;
  margin-bottom: 10px;
}
.text-input input {
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 9px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
}
.reset_title {
  float: left !important;
  margin: 0 0 20px 0px;
}
/*Latest modern browsers */
.text-input input::placeholder {
  color: #a9a9a9;
}
/* for Opera,webkit chrome browsers */
.text-input input::-webkit-input-placeholder {
  color: #a9a9a9;
}
/*firefox 19+ versions*/
.text-input input::-moz-placeholder {
  color: #a9a9a9;
}
/*IE  versions*/
.text-input input::-ms-placeholder {
  color: #a9a9a9;
}
.modalContainer {
  background-color: #f2f3f7 !important;
  padding: 1.5rem !important;
  text-align: center;
  width: 100%;
  border-radius: 10px;
}
.modal_btn {
  width: 100% !important;
}
.error-box {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.eme-conatiner {
  margin-left: 40px;
  margin-top: 10px;
}
.col-lg-8 {
  width: 68.666667% !important;
}
/* -------------- responsive -------------- */
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px !important;
  }
}

@media only screen and (max-width: 990px) {
  .error-box {
    display: contents !important;
  }
}

@media only screen and (max-width: 768px) {
  .stu_name {
    margin-left: 10px;
  }
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}

@media only screen and (max-width: 450px) {
  .stu_att_img {
    width: 20%;
  }
  .stu_name {
    margin-left: 15px;
    font-size: 16px;
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) {
    .modal-dialog {
      max-width: 1000px !important;
    }
  }