.student_profile_main {
  float: left;
  width: 100%;
}
.sec_mar_top {
  margin-top: 75px;
}
.student_profile_top_part {
  float: left;
  width: 100%;
  float: left;
  width: 100%;
  border-radius: 5px;
  padding: 65px 15px 65px 55px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgb(0, 93, 161) 55%,
    rgb(0, 93, 161) 100%
  );
}
.student_profile_picture {
  float: left;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
}
.student_profile_picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.student_profile_name {
  float: left;
  padding: 68px 0 0 10px;
}
.student_profile_btn {
  float: right;
  display: block;
  padding: 55px 0 0 0;
}
.student_profile_btn a {
  float: right;
  color: #323232;
}
.btn_logout {
  color: var(--blue) !important;
}
.update_profile {
  text-decoration: none;
  margin: 10px 0;
}
.edit-icon {
  margin-right: 10px;
}
.edit-icon img {
  width: 15px;
  margin-bottom: 4px;
}
.btn-course {
  float: left;
}
.tg_e_icon {
  width: 16px;
  height: 16px;
}
