.ph-item {
  position: relative;
  display: block !important;
  flex-wrap: wrap;
  margin: 0 5px 30px 0;
  padding: 12px 0px 15px;
  overflow: hidden;
  direction: ltr;
  background-color: #fff;
  border: none !important;
  border-radius: 5px !important;
}
.gift-item-box {
  position: relative;
  left: 10px;
  width: 97%;
  border-radius: 10px;
  padding: 10px !important;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%) !important;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%) !important;
}
.tg-Adsde {
  margin-left: 65px;
}
.ph-icon {
  width: 70px !important;
  height: 70px !important;
}
.ph-picture-list {
  width: 100%;
  height: 460px !important;
}
.ph-picture {
  width: 100%;
  height: 530px !important;
}
.ph-item,
.ph-item *,
.ph-item :after,
.ph-item :before {
  box-sizing: border-box;
  border-radius: 5px;
}
.data-list {
  width: 50px !important;
  height: 50px !important;
}
.about {
  margin: 35px 0 15px 0 !important;
  height: 15px !important;
}
.description {
  height: 12px !important;
  margin-bottom: 5px !important;
}
.payment-status {
  height: 15px !important;
}
.details-btn {
  height: 28px !important;
}
.view-details-btn {
  height: 35px !important;
}
.color {
  background-color: #d0d5d8 !important;
}
.leave {
  max-width: 100px !important;
  margin-right: 23px !important;
  margin-top: 17px !important;
  height: 13px !important;
}
.leave-loading-title {
  margin-top: 17px !important;
  height: 13px !important;
}
.apply-leave {
  height: 32px !important;
}
.avatar {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}
.s-title {
  height: 16px !important;
  margin-top: 22px !important;
  margin-left: 16px;
}
.s-desc {
  height: 16px !important;
  margin-top: 22px !important;
  margin-left: 25px;
}
.s-gift {
  height: 16px !important;
  width: 100px !important;
  margin-top: 22px !important;
  margin-right: 20px;
  margin-left: 50px;
}
.s-btn {
  height: 31px !important;
  width: 90px !important;
  margin-top: 15px !important;
  border-radius: 20px;
}

@media only screen and (max-width: 990px) {
  .tg-Adsde {
    margin-left: 16px !important;
  }
}
@media only screen and (max-width: 768px) {
  .ph-picture {
    height: 330px !important;
  }
  .ph-picture-list {
    width: 100%;
    height: 360px !important;
  }
  .leave {
    margin-right: 80px !important;
  }
  .leave-loading-title {
    margin-bottom: 10px !important;
  }
  .s-desc {
    margin: 13px 0 0 0 !important;
  }
  .s-gift {
    margin-left: 0px;
  }
  .empty-sp {
    width: 410px;
  }
}

@media only screen and (max-width: 450px) {
  .ph-picture {
    height: 245px !important;
  }
  .ph-picture-list {
    width: 100%;
    height: 360px !important;
  }
  .leave {
    margin-right: 35px !important;
  }
  .leave-loading-title {
    margin-bottom: 10px !important;
  }
  .s-gift {
    margin-right: 0px;
  }
  .empty-sp {
    width: 110px;
  }
}

@media only screen and (max-width: 400px) {
  .ph-picture {
    height: 245px !important;
  }
  .ph-picture-list {
    width: 100%;
    height: 200px !important;
  }
}
