@media only screen and (max-width: 1080) {
  .tg_arrow {
    position: relative;
    left: 28%;
    z-index: 2;
    margin-top: 6px;
  }
}

@media only screen and (max-width: 991px) {
  .student_profile_top_part {
    padding: 20px 0px;
    background: linear-gradient(
      0deg,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 70%,
      rgb(0, 93, 161) 55%,
      rgb(0, 93, 161) 100%
    );
  }
  .tg_arrow {
    position: relative;
    left: 37%;
    z-index: 2;
    margin-top: 6px;
  }
  .student_profile_picture {
    float: none;
    margin: 0px auto;
    display: table;
  }
  .student_profile_name {
    padding: 0px;
    width: 100%;
    text-align: center;
  }
  .update_profile {
    margin-left: 20px;
  }
  .brder_btn {
    margin-right: 20px;
  }
  .student_profile_btn {
    width: 100%;
    float: left !important;
    justify-content: space-between;
    margin: 0px auto;
    display: block;
    padding: 20px 0 0 0;
  }
  .student_profile_btn a {
    float: left !important;
  }

  .mobile_menu_btn {
    display: block;
  }
  .desktop_menu {
    display: none;
  }
  header .main_logo {
    text-align: center;
  }
  header .main_logo img {
    width: 290px;
    float: none;
    padding: 0 0 10px 0;
  }
  .header_right_side {
    float: right;
    margin-top: 0px;
  }
  #frame #sidepanel {
    width: 40%;
  }
  #frame .content {
    width: 60%;
  }
  .feed_connect_peers_main_img {
    height: 125px;
  }
  .cstm_mt_991 {
    margin-top: 30px;
  }
  .home_fifth_sec_custm {
    padding: 70px 0;
  }
  .student_profile_btn {
    display: flex;
    justify-content: space-between;
  }
  .btn_logout {
    width: 30% !important;
  }
}

@media only screen and (max-width: 768px) {
  #frame #sidepanel {
    width: 10%;
  }
  #frame .content {
    width: 88%;
  }
  .tg_arrow {
    position: relative;
    left: 50%;
    z-index: 2;
    margin-top: 6px;
  }
  .friend_common_user_profile {
    width: 10%;
  }
  .requst_common_user_profile {
    width: 10%;
  }
  .search_bar {
    width: 100%;
    margin: 0 0 0 0;
  }
  .filter_search_bar_btn {
    margin: 30px 0 0 0;
  }
  .home_scnd_sec_right_box_marg {
    margin: 30px 0 0 0;
  }
  .home_scnd_sec_left h2 {
    margin: 40px 0 0 0;
    text-align: center;
  }
  .home_scnd_sec_left h3 {
    text-align: center;
  }
  .home_scnd_sec_left p {
    text-align: center;
  }
  .home_fifth_sec_custm {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 515px) {
  #frame .content {
    width: 83%;
  }
  .tg_arrow {
    position: relative;
    left: 55%;
    z-index: 2;
    margin-top: 6px;
  }
}
@media only screen and (max-width: 490) {
  .tg_arrow {
    position: relative;
    left: 57%;
    z-index: 2;
    margin-top: 6px;
  }
}

@media only screen and (max-width: 450px) {
  .feed_common_user_profile_txt {
    width: 80%;
  }
  .tg_arrow {
    position: relative;
    left: 63%;
    z-index: 2;
    margin-top: 6px;
  }
  .custome_float_left {
    float: left !important;
  }
  .custome_width_100 {
    width: 100% !important;
  }
  .peers_common_user_profile_btn {
    float: left;
    width: 100%;
    border-bottom: 1px solid #c1c1c1;
    padding: 12px 0;
  }
  .peers_common_user_profile_btn_reject {
    float: left;
    width: 100%;
    padding: 13px 0;
    margin: 0px !important;
    border-bottom: 1px solid #c1c1c1;
  }
  .connect_with_peers_main .form-select {
    width: 50%;
  }
  .school_orgazination_main_baner_name {
    width: 80%;
  }
  .friend_common_user_profile {
    width: 15%;
  }
  .requst_common_user_profile {
    width: 15%;
  }
}

@media only screen and (max-width: 400px) {
  header .main_logo img {
    width: 200px;
  }
  .tg_arrow {
    position: relative;
    left: 73%;
    z-index: 2;
    margin-top: 6px;
  }
  .student_profile_btn {
    display: grid;
    justify-content: center;
  }
  .brder_btn {
    margin-left: 35px;
    width: 75% !important;
  }
  .update_profile {
    margin-left: 0px;
  }
  .bld_secnd_title {
    font-size: 16px !important;
  }
  .gnrl_para {
    font-size: 14px !important;
  }
  .cust_width {
    height: fit-content;
  }
}

@media only screen and (max-width: 360px) {
  .tg_arrow {
    position: relative;
    left: 85%;
    z-index: 2;
    margin-top: 6px;
  }
  .bld_secnd_title {
    font-size: 16px !important;
  }
  .gnrl_para {
    font-size: 14px !important;
  }
  .tg-ctsm-box {
    display: flex;
    flex-direction: column;
  }
  .feed_date_time img {
    margin: 0px 6px 5px 8px;
  }
  .view_more_btn {
    float: left !important;
    font-size: 13px;
    margin-left: 8px;
  }
  .my_course_btn {
    font-size: 14px;
    padding: 5px 20px;
    margin-bottom: 5px;
  }
  .my_btn_gift {
    font-size: 14px;
    padding: 5px 48px;
    margin: 5px 0 0 0;
  }
}
