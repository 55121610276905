.slick-active button {
  opacity: 0.75;
  color: #000 !important;
  background-color: #000 !important;
  content: "1" !important;
}
.slick-dots .slick-active div {
  background-color: #005da1 !important;
}
.tg_list .slick-dots > li {
  width: 5% !important;
  margin: 0px !important;
  height: 5px !important;
  margin-bottom: 20px !important;
}
.slick-dots li {
  width: 90px !important;
  margin: 0px !important;
  height: 5px !important;
  margin-bottom: 20px !important;
}
.slick-prev:before {
  content: none !important;
  display: none !important;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 0 !important;
  display: none !important;
}

.slick-next:focus:before,
.slick-next:before,
.slick-prev:focus:before,
.slick-prev:before {
  opacity: 0 !important;
  display: none !important;
}
.slick-prev {
  left: -60px !important;
}
.slick-dots ul {
  padding-left: 0px !important;
}
.image-conatiner {
  height: 403px !important;
}
.tg-cust-mr {
  margin-bottom: 50px !important;
}
.tg-cust-dots {
  margin: 30px 0px !important;
}
.tg-slider-image {
  width: 1000px !important;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 1200px) {
  .tg-cust-mr {
    max-width: 930px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .tg-cust-mr {
    max-width: 850px !important;
  }
}
@media only screen and (max-width: 990px) {
  .tg-cust-mr {
    max-width: 770px !important;
  }
}
@media only screen and (max-width: 820px) {
  .tg-cust-mr {
    max-width: 700px !important;
  }
}
@media only screen and (max-width: 768px) {
  .slick-dots li {
    width: 60px !important;
    margin-bottom: 15px !important;
  }
  .tg-cust-mr {
    max-width: 610px !important;
  }
  .image-conatiner {
    height: 310px !important;
  }
}
@media only screen and (max-width: 600px) {
  .tg-cust-mr {
    max-width: 460px !important;
  }
  .image-conatiner {
    height: 250px !important;
  }
}
@media only screen and (max-width: 500px) {
  .tg-cust-mr {
    max-width: 350px !important;
  }
  .image-conatiner {
    height: 185px !important;
  }
}

@media only screen and (max-width: 480px) {
  .slick-dots li {
    width: 35px !important;
    margin-bottom: 15px !important;
  }
  .tg-cust-mr {
    max-width: 315px !important;
  }
  .image-conatiner {
    height: 140px !important;
  }
  .slick-next {
    right: -16px;
  }
  .slick-prev {
    left: -55px !important;
  }
}

@media only screen and (max-width: 400px) {
  .tg-cust-mr {
    max-width: 280px !important;
  }
}