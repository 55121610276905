:root {
  --blue: #005da1;
  --white: #fff;
}
* {
  margin: 0px 0px;
  padding: 0px;
  outline: none;
  list-style-type: none;
  box-sizing: border-box;
}
body {
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #f1f3f7;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #323232 !important;
}

.view-more-paginate-btn {
  float: right !important;
}
table {
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px var(--blue);
  font-size: 14px;
}
.mobile_menu {
  height: fit-content;
  width: fit-content;
}
.pl-8 {
  padding-left: 8px !important;
}
.pr-8 {
  padding-right: 8px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.font-18 {
  font-size: 18px !important;
}
input:disabled {
  background: rgba(218, 218, 218, 0.774) !important;
  cursor: not-allowed !important;
}
.disabled-btn {
  cursor: not-allowed !important;
  opacity: 0.6;
}
select:disabled {
  background: rgba(218, 218, 218, 0.774) !important;
  cursor: not-allowed !important;
}
/* button:disabled {
  cursor: not-allowed !important;
} */
input,
textarea,
select {
  color: #323232 !important;
}

/* .modal-body-container select:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
} */

select option {
  color: #323232 !important;
}

.firefox-display {
  display: flow-root list-item !important;
}

.invalid {
  border: 2px solid #dc3545 !important;
}

.invalid-text {
  color: #dc3545;
  font-size: 14px;
  margin-bottom: 10px;
  float: left;
}
.valid {
  border: none !important;
}
/* ---------- Toastify color ---------- */
.Toastify__toast--error {
  background: #dc3545 !important;
}
.Toastify__toast--success {
  background: #27ae60 !important;
}

.page-link {
  width: 33px !important;
  height: 33px !important;
  border-radius: 50% !important;
  color: var(--blue);
  font-size: 13px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid var(--blue) !important;
  margin: 0 5px !important ;
}
.page-link:hover {
  color: var(--blue);
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--blue);
  border-color: var(--blue);
}
.select-limit {
  width: 70px !important;
  border: 1px solid var(--blue) !important;
  padding: 5px 12px !important;
  background-image: url("../images/down-arrow-blue.png") !important;
  background-position: 85% center !important;
}
/*---------------------------------------------- general css start ----------------------------------------------*/
.sec_mar_top {
  margin-top: 75px;
}
.main_title {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
  color: #323232;
  font-size: 30px;
  font-weight: bold;
}
.main-loader {
  position: fixed;
  top: 50%;
  left: 50%;
}
.secnd_title {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
  color: #646464;
  font-size: 20px;
}
.bld_secnd_title {
  float: left;
  margin: 10px 0px 5px 0px;
  padding: 0px;
  color: #323232;
  font-size: 18px;
  font-weight: bold;
}
.title_para {
  width: 100%;
  float: left;
  margin: 15px 0 0 0;
  padding: 0px;
  color: #969696;
  font-size: 16px;
}
.title_para2 {
  width: 100%;
  float: left;
  margin: 15px 0 0 0;
  padding: 0px;
  color: #969696;
  font-size: 20px;
}
.gnrl_para {
  width: 100%;
  float: left;
  margin: 5px 0 0 0;
  padding: 0px;
  color: #323232;
  font-size: 18px;
}
.gnrl_para2 {
  width: 100%;
  float: left;
  margin: 5px 0 0 0;
  padding: 0px;
  color: #323232;
  font-size: 20px;
}

.brder_btn {
  border: 1px solid var(--blue);
  background-color: transparent;
  /* width: 50%; */
  color: var(--blue);
  padding: 5px 20px;
  border-radius: 5px;
  float: right !important;
}

.blue_fill_btn {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
  padding: 8px 13px;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}

.view-details-pending {
  border: 1px solid #e74c3c;
  background-color: #e74c3c;
  color: #fff;
  float: right;
  padding: 5px 20px;
  font-size: 14px;
  margin-top: 6px;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.view-details-paid {
  border: 1px solid #27ae60;
  background-color: #27ae60;
  color: #fff;
  float: right;
  padding: 5px 20px;
  font-size: 14px;
  margin-top: 6px;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.blue_fill_btn:hover {
  background-color: transparent;
  color: var(--blue);
  transition: 0.3s ease;
}
.blue_fill_btn:disabled {
  background-color: transparent;
  color: var(--blue);
  transition: 0.3s ease;
  cursor: not-allowed;
}

.red_brder_btn {
  float: left;
  border: 1px solid #e73030;
  background-color: transparent;
  color: #e73030;
  padding: 6px 14px;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
  font-size: 14px;
}
.brdr_botton {
  border-bottom: 1px solid #c1c1c1;
}

.verify_email {
  float: left;
  width: 100%;
  margin: 0px;
  color: var(--blue);
}
.verify_email a {
  color: var(--blue) !important;
}

/*---------------------------------------------- general css end ----------------------------------------------*/
/*---------------------------------------------- general css start ----------------------------------------------*/
.Weight {
  font-weight: bold;
}
.modal-body-container {
  width: 100%;
  padding: 25px;
  background-color: #f1f3f7;
  border-radius: 8px;
}
.course-img {
  border-radius: 50%;
}
.image_main {
  width: 70px !important;
  height: 70px !important;
  border-radius: 100% !important;
}

.peers_common_user_profile {
  float: left;
}

.peers_common_user_profile .common_user_profile_img {
  float: left;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.peers_common_user_profile .common_user_profile_img img {
  width: 100%;
}
.peers_common_user_profile_txt {
  float: left;
  padding: 0 0 0 15px;
}
.peers_common_user_profile_txt .school_name {
  float: left;
  width: 100%;
  font-size: 10px;
  color: #969696;
}

.edit_student_profile_main input {
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #323232;
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
}
/* .form-select {
  color: #969696;
} */
.form-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  color: #959595;
  cursor: pointer;
  background-image: url("../images/down-arrow.svg") !important;
  background-repeat: no-repeat;
  background-size: 15px;
  border: 1px solid #959595;
  background-position: 97% center;
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
}
.update_profile_select {
  background-image: url("../images/down-arrow.svg");
  background-size: 15px;
  border: 1px solid #969696 !important;
  border: none !important;
  box-shadow: none !important;
}
.form-select option {
  padding: 10px !important;
}
.form-select:focus {
  border-color: #b9b9b9;
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
select option {
  margin: 40px;
  color: #959595;
  /* background: rgba(0, 0, 0, 0.3); */
  /* color: #fff; */
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.text-color {
  color: #969696;
}
.feed_common_user_profile_txt {
  margin-left: 0px;
  margin-top: 15px;
}

.my_course_btn {
  border: 1px solid white;
  background-color: #fff;
  color: black;
  padding: 5px 30px;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.my_course_btn img {
  width: 21px;
  margin: 0px 8px 2px 0px;
}
.my-active {
  border: 1px solid var(--blue) !important;
  background-color: var(--blue) !important;
  color: white !important;
}
.margin-0 {
  margin-left: 0px !important;
}
.my_btn_gift {
  color: black;
  margin-left: 10px;
  padding: 5px 40px;
  border: 1px solid white;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.my_btn_gift img {
  width: 17px;
  margin: 0px 9px 5px 0px;
}
.tg_btn_toggle .tg_gift_togl:hover {
  background-color: transparent;
  color: var(--blue);
  transition: 0.7s ease;
}
.tg_btn_toggle {
  margin-left: 0px !important;
  padding: 5px 30px !important;
  margin-right: 10px;
}
.tg_gift_togl {
  padding: 5px 40px !important;
}

.tg_cust_pa {
  padding-left: 55px;
}
.tg_mar_txt {
  margin-top: 15px !important;
}
.tg_pad_img {
  padding-right: 0 !important;
  width: 10%;
}
/*---------------------------------------------- Select type page css start  ----------------------------------------------*/
.select_type_main {
  float: left;
  width: 100%;
}
.select_type_main_card {
  float: left;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
}
.select_type_main_card a {
  float: left;
  width: 100%;
}
.select_type_main_card:hover {
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease;
}

.select_type_main_card_img1 {
  float: left;
  width: 100%;
  height: 120px;
}
.select_type_main_card_img1 img {
  width: 80px;
  margin: 10px 0 0 0;
}

.select_type_main_card_img2 {
  float: left;
  width: 100%;
  height: 120px;
}
.select_type_main_card_img2 img {
  width: 80px;
  margin: 18px 0 0 0;
}

.select_type_main_card_img3 {
  float: left;
  width: 100%;
  height: 120px;
}
.select_type_main_card_img3 img {
  width: 80px;
  margin: 22px 0 0 0;
}

.select_type_main_card .gnrl_para span {
  font-weight: bold;
}
.close_btn {
  float: right !important;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  bottom: 4px;
  right: 7px;
  font-weight: 400;
}
.tg_pad_t {
  padding-left: 12px !important;
}
.cust_container {
  padding: 0 25px 0 25px !important;
  display: flex;
  flex-direction: column;
}
.text_truncate {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
.tg_course_icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.tg_gft_icon {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.text_area {
  padding: 12px;
}
.cust_width {
  height: 73px;
}
.email_icon {
  width: 100% !important;
  height: 70% !important;
  object-fit: contain;
}
.tg-display {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tg-content {
  width: 100%;
  height: 280px;
}
.tg-cust-height {
  height: 75px !important;
}
.payment_modal {
  text-align: center;
}
.StripeElement {
  height: 45px !important;
  padding: 0px 12px 0px 15px !important;
  color: #32325d !important;
  background-color: #add8e6 !important;
  border: 1px solid #1e90ff !important;
  border-radius: 7px !important;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.card_box {
  display: inline !important;
}
.row {
  --bs-gutter-x: 0.3rem !important;
}
.hide{
  display: none;
}