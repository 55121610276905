.reset_title {
  float: left !important;
  margin: 0 0 20px 0px;
}
.tg_payment {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.check_btn {
  width: 20px;
  height: 20px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.check_labl {
  position: relative;
  bottom: 5px;
}
.pay_btn{
  margin-top: 20px !important;
}
.card_pay_box{
  border: 1px solid #D3D3D3 !important;
  padding: 15px;
  border-radius: 15px;
}
.card-pay{
  color: black;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 20px;
}
.no-pay-ava{
  text-align: center;
}

.offlinePayment{
    display: flex;
}

.offlinePayment div{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.paymentModal{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.paymentModal .modal-content{
    padding: 1.5rem !important;
    background-color: #f2f3f7 !important;
    border-radius: 10px;
}

.offlinePaymentItem div{
    word-break: break-word;

}

.offlinePaymentItem div:nth-child(odd){
    margin-bottom: 20px;
}

.offlinePaymentItem div:nth-child(even){
    margin-bottom: 10px;
}

.offlinePaymentItem:nth-child(1){
    margin-right: 50px;
}

/* @media (max-width: 800px){
    .paymentModal .modal-content{
        width: 1000px !important;
    }
} */