.custome-img-radius {
  border-radius: 6px !important;
  margin-bottom: 20px;
}
.tg_checked {
  width: 12px !important;
}
.tg_checkin {
  color: #4bb543 !important;
  margin-left: 4px;
  font-size: 14px;
}
.tg_arrow {
  position: relative;
  left: 23%;
  z-index: 2;
  margin-top: 6px;
}
.tg_picker {
  width: 100% !important;
  float: left;
  margin-bottom: 25px;
}
.custm_datepicker {
  margin-left: 2% !important;
  padding: 2px 40px 2px 10px;
  border-radius: 4px;
  border: 1px solid #969696 !important;
  cursor: pointer;
  width: 250px !important;
}
.tg_dropicon {
  background: url(../../../assets/images/down-arrow.svg) no-repeat !important;
  background-position: 95% 7px !important; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 15px !important;
}
.coatch-para {
  color: #969696;
}
.margin-cutome {
  padding-left: 10px !important;
}
.custom-form-select {
  padding: 0.3rem 2rem 0.3rem 0.75rem !important;
  font-size: 13px !important;
  margin: 0px 8px;
  width: 100%;
}
.class_scheduled_bracket {
  font-weight: 400 !important;
  padding-left: 7px !important;
}
.custome-tabel > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
  background-color: #005ea2;
  color: white;
  text-align: center;
  font-weight: 500;
}
.custome-tabel tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0 !important;
  text-align: center;
  padding: 0.3rem !important;
}

.custome-tabel table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}
th {
  border-right: 1px solid #eeeeef !important;
}
td {
  border-right: 1px solid #005ea2 !important;
  border-bottom: 0px !important;
  padding: 0.6rem !important;
  color: #646464;
}

th:last-child,
td:last-child {
  border-right: none !important;
}

.custome-tabel button {
  font-size: 14px;
  padding: 0.1rem 2rem;
  background-color: #005ea2;
}

.custome-tabel table {
  border: 0px !important;
  border-radius: 0px !important;
}

@media only screen and (max-width: 450px) {
  .table-responsive-sm table {
    width: 100% !important;
  }
  .custm_datepicker{
    width: 100% !important;
  }
}
