.select-box {
    height: 42px;
    overflow: auto;
}
.select-box .select-main {
    width: 108px;
    height: 42px;
    margin-right: 10px;
    float: left;
    border-radius: 5px;
    /*padding-left: 10px;*/
    outline: none;
    padding: 5px 10px;
    /*box-shadow: 3px 4px 10px 0px rgb(0 0 0);*/
}
