:root {
  --blue: #005da1;
}
.color_green {
  color: #4bb543;
}
.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
  display: block;
}
.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}
.header-fixed > tbody {
  overflow-y: auto;
  height: 210px;
  cursor: pointer;
}
.header-fixed ::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
.header-fixed ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.header-fixed ::-webkit-scrollbar-thumb {
  background: #005da1;
}

/* Handle on hover */
.header-fixed ::-webkit-scrollbar-thumb:hover {
  background: #105e96;
}
.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
  width: 50%;
  float: left;
  text-align: center;
}
.tg-table-width {
  float: left !important;
  width: 60% !important;
  text-align: center !important;
  margin-left: 8px !important;
}
.header-fixed {
  float: left !important;
  width: 60% !important;
  text-align: center !important;
  margin-left: 8px !important;
}
.tg-table-width th {
  color: #fff;
  font-weight: normal;
}
.header-fixed th {
  background-color: var(--blue) !important;
  font-weight: normal;
  color: #fff;
}
.tg-head {
  padding: 0 !important;
  padding-right: 7px !important;
}
.tg-colorhead {
  background-color: var(--blue) !important;
  border-radius: 6px 6px 0 0 !important;
}
.tg-table-width td {
  font-size: 16px !important;
}
.header-fixed td {
  font-size: 16px !important;
}
.tg-body {
  margin-bottom: 3px;
}
/* table*/
.feed_common_user_profile {
  float: left;
  display: inline-flex;
  align-items: center;
}
.feed_common_user_profile .common_user_profile_img {
  float: left;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
/* .feed_common_user_profile .common_user_profile_img img {
  width: 100%;
} */
.feed_common_user_profile_txt {
  float: left;
  padding: 0px;
}
.coach_name {
  margin: 0px 0 0 15px;
  font-size: 18px;
}
.tg_width {
  height: 20px;
}
.course-details img {
  width: 100%;
  border-radius: 5px;
}

table th:first-child {
  border-radius: 8px 0 0 0;
}
table th:last-child {
  border-radius: 0 8px 0 0;
}
.border-left-white {
  border-left: 1px solid #fff;
}
.border-right-white {
  border-right: 1px solid #fff;
}
.border-left-blue {
  border-left: 1px solid var(--blue);
}
.border-right-blue {
  border-right: 1px solid var(--blue);
}
table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.package-details {
  background: #f1f3f7;
  width: 80%;
  border-radius: 8px;
  padding: 20px 0px;
}
.position-left {
  float: left;
}
.position-right {
  float: right;
}
.package-details .package-title {
  margin: 20px 0px 25px 0px;
  padding: 0px;
  color: var(--blue);
  font-size: 18px;
  font-weight: bold;
}
.package-details .title {
  margin: 10px 0px 10px 0px;
  padding: 0px;
  font-weight: bold;
}
.package-details .details {
  margin: 10px 0px 10px 0px;
  padding: 0px;
}
.buy-now-btn {
  width: 65%;
}
.divider {
  width: 50%;
  color: #808080cc !important;
  margin: 15px 63px 0px 63px !important;
}
.profile_img {
  object-fit: cover !important;
  width: 100% !important;
  height: inherit !important;
}
@media only screen and (max-width: 991px) {
  .header-fixed > th {
    width: 50% !important;
  }
  .header-fixed {
    width: 90% !important;
  }
  .package-details {
    width: 100%;
  }
  .divider {
    margin: 15px 37px 0px 43px !important;
  }
}
@media only screen and (max-width: 768px) {
  .header-fixed {
    width: 96% !important;
  }
  .tg-table-width {
    width: 70% !important;
  }
  .header-fixed {
    width: 70% !important;
  }
  .package-details {
    width: 90%;
  }
  .divider {
    margin: 15px 37px 0px 41px !important;
  }
}
@media only screen and (max-width: 550px) {
  .tg-table-width {
    width: 80% !important;
  }
  .header-fixed {
    width: 80% !important;
  }
}
@media only screen and (max-width: 450px) {
  .header-fixed {
    width: 96% !important;
  }
  .tg-table-width {
    width: 100% !important;
  }
  .header-fixed {
    width: 100% !important;
  }
  .package-details {
    width: 90%;
  }
  .divider {
    margin: 15px 70px 0px 70px !important;
  }
}
@media only screen and (max-width: 3040px) {
  .header-fixed > th {
    width: 50% !important;
  }
}
