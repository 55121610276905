.edit_profile_upload_pic .profile-pic {
  max-width: 200px;
  max-height: 200px;
  display: block;
}
.edit_profile_upload_pic .file-upload {
  display: none;
}
.circle_main {
  float: none;
  margin: 0px auto;
  display: table;
  position: relative;
}
.edit_profile_upload_pic .circle {
  border-radius: 1000px !important;
  overflow: hidden;
  width: 128px;
  height: 128px;
  border: 6px solid rgba(255, 255, 255, 0.7);
}
.edit_profile_upload_pic .circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.edit_profile_upload_pic .p-image {
  position: absolute;
  top: 86px;
  right: 1px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 50%;
  cursor: pointer;
}
.edit_profile_upload_pic .p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.edit_profile_upload_pic .upload-button {
  font-size: 1.1em;
  color: #969696;
}
.edit_profile_upload_pic .upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

.tg-cont-ad {
  width: 100% !important;
  float: left;
}
.tg-coach-pswrd-btn {
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #696969;
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
}
/* .tg-coach-pswrd-btn:disabled {
  background: rgba(218, 218, 218, 0.774) !important;
  cursor: not-allowed !important;
} */
.tg-edit-icon {
  float: right;
  /* position: relative; */
  bottom: 37px;
  margin-right: 1px;
  cursor: pointer;
  height: 27px;
  padding: 0px 9px;
  /* background: white; */
  width: 37px;
}
.tg_border {
  width: 100% !important;
  float: left;
}
.tg_span {
  margin: 0 10px;
}
.tg_mart {
  margin-left: 0px !important;
}
.colon_space {
  margin-left: 36px;
}
.bg_color {
  background-color: #f1f3f7 !important;
}
.cust_pad_w {
  padding: 10px 0 10px 7px !important;
}
.tg_e_icon {
  width: 16px;
  height: 16px;
  margin-bottom: 5px;
}
.otp-input {
  width: 90%;
  background-color: #fff;
  border: 0px;
  padding: 9px 16px;
  border-radius: 5px;
  margin-left: 6px;
  color: #969696;
  -webkit-box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
}
.otp-label {
  text-align: left;
  margin: 0 0 15px 7px;
  width: 100%;
}
.phone-input input:disabled {
  background: rgba(218, 218, 218, 0.774) !important;
  cursor: pointer !important;
}
.country_code_select {
  background-image: url("../../../assets/images/down-arrow-blue.png");
  background-size: 15px;
  width: 98% !important;
  border: none !important;
  font-size: 14px !important;
  padding: 10.5px 34px 10.5px 9px !important;
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%) !important;
}
.mobile-btn {
  width: 100%;
  text-align: left !important;
  position: relative;
  background-color: white !important;
  height: 37px !important;
  padding: 8px 0px 7px 13px !important;
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%) !important;
  border-radius: 5px !important;
}
.mobile-btn:disabled {
  background: rgba(206, 205, 205, 0.808) !important;
  cursor: pointer !important;
  color: black !important;
  opacity: 1 !important;
}
