.gifts_user_profile {
  float: left;
  display: flex;
  align-items: center;
}
.enrolled_students_title {
  margin-left: 10px;
  margin-top: 15px;
}
.gifts_user_profile .common_user_profile_img {
  float: left;
}
.gifts_user_profile .user_name {
  float: left;
  margin-left: 15px;
}
.gift_description {
  display: flex;
  align-items: center;
}
.gifts_user_profile .common_user_profile_img img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.gift_given {
  float: left;
  padding: 3px 0 0 0px;
}
.gifts_switch {
  float: right;
}
.gifts_user_profile_txt {
  float: left;
  padding: 0 0 0 15px;
}
.gifts_user_profile_txt .school_name {
  float: left;
  width: 100%;
  font-size: 10px;
  color: #969696;
}
.select-lbl {
  float: right;
  padding: 6px;
  margin-right: 10px;
}
.course-select {
  width: 45% !important;
  float: right !important;
}

@media only screen and (max-width: 450px) {
  .course-select {
    width: 75% !important;
    float: right !important;
  }
}
