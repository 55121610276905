.flex_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}

.viewMore{
    margin: 0 !important;
}