.peers_common_user_profile_txt {
  float: left;
  width: 85%;
  padding: 0 0 0 15px;
  margin: 0 0 0 11px;
}
.notification_time {
  text-align: right;
  font-size: 10px;
  margin-top: 14px !important;
  color: #969696;
}
.notification_area {
  min-height: 350px !important;
}
.common_user_profile_img .notification-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* ------------ Responsive ------------ */
@media only screen and (max-width: 768px) {
  .peers_common_user_profile_txt {
    float: left;
    width: 85%;
    padding: 0 0 0 15px;
    margin: 0 0 0 11px;
  }
  .peers_common_user_profile {
    width: 10%;
  }
  .notification_time {
    margin-top: 11px !important;
  }
}
@media only screen and (max-width: 450px) {
  .peers_common_user_profile {
    width: 10%;
  }
  .notification_time {
    margin-top: 0px !important;
  }
}
