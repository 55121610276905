/*--------------------------------------------Student login and register css start --------------------------------------------*/

.stdnt_login_registr_main {
  float: left;
  width: 100%;
}
.stdnt_login_registr_main .main_illustration img {
  width: 80%;
}
.stdnt_login_registr_main .secnd_title {
  border-bottom: 1px solid #c1c1c1;
  padding: 0 0 10px 0;
}
.stdnt_login_registr_main input {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.stdnt_login_registr_main .form-select {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  cursor: pointer;
  background-image: url("../../../assets/images/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 97% center;
}
.stdnt_login_registr_main a {
  color: #969696;
  text-decoration: none;
}
.register-success-img {
  width: 50%;
  padding-right: 15px;
}
.btn_ok {
  width: 40% !important;
  margin: 45px 0px 0px 0px;
}
.size-chart {
  width: 100%;
  float: left;
  color: #646464;
  cursor: pointer;
  font-size: 16px;
}
.size-chart:hover {
  color: var(--blue);
}
.title_without_border {
  border: none !important;
  /* padding: 0px !important; */
}
.select-box-shadow {
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%) !important;
}
.blue_border {
  background: var(--blue);
  height: 1.5px !important;
  width: 15%;
  float: left;
}
.register_border_width_blue {
  width: 20% !important;
}
.register_border_width_gray {
  width: 80% !important;
}
.gray_border {
  background: #c1c1c19e;
  height: 1.5px !important;
  width: 85%;
  float: left;
}

.stdnt_login_registr_main .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  padding:0;
}
/*----------------------- Student login and register css end -----------------------*/

/*----------------------- Responsive css start -----------------------*/

@media only screen and (max-width: 450px) {
  .stdnt_login_registr_main .main_illustration img {
    width: 100%;
    padding: 0px 12px;
  }
}

/*----------------------- Responsive css end -----------------------*/
