.my_gifts_components {
  padding: 5px 15px 5px 20px !important;
  min-height: 370px;
}
.clock_mr {
  margin-right: 10px !important;
}
.gift_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recieved-gift-title {
  padding-left: 17px !important;
  padding-top: 5px !important;
}
.gift_status_cont {
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  width: 30%;
}
.tg_info {
  margin-right: 16px !important;
}
.gift-item {
  position: relative;
  width: 100%;
  border-radius: 10px;
  padding: 25px 15px 25px 25px !important;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%) !important;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 15%) !important;
}
.read-or-hide {
  color: #005da1;
  cursor: pointer;
}
.container_pos {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  right: 15px;
  bottom: 10px;
}
.container_position {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  position: relative;
  bottom: 10px;
}
.gift-item img {
  width: 45px;
  float: left;
}
.gift-item .gift-title {
  padding-left: 20px;
}
.gift_truncate {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
.gift-container {
  display: flex;
  align-items: center;
  width: 75% !important;
}

@media only screen and (max-width: 990px) {
  .gift_status_cont {
    width: 30% !important;
  }
  .gift_main {
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px !important;
  }
  .gift-container {
    flex-direction: column;
    width: 100% !important;
  }
  .gift-item .gift-title {
    padding-left: 0 !important;
  }
  .gift_status_cont {
    width: 100% !important;
    margin-top: 10px !important;
    flex-direction: column !important;
    align-items: center;
  }
  .container_pos {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    position: relative;
    top: 10px;
    right: 0px;
    bottom: 0px;
    align-items: baseline;
  }
  .container_position {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 10px;
    font-size: 14px;
    align-items: baseline;
  }
  .tg_position {
    position: relative;
    left: 55% !important;
  }
  .tg_pos {
    position: relative;
    left: 45% !important;
  }
  .tg_info {
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 800px) {
  .tg_position {
    position: relative;
    left: 80% !important;
  }
  .tg_pos {
    position: relative;
    left: 70% !important;
  }
}

@media only screen and (max-width: 680px) {
  .tg_position {
    position: relative;
    left: 82% !important;
  }
  .tg_pos {
    position: relative;
    left: 70% !important;
  }
}
@media only screen and (max-width: 550px) {
  .tg_position {
    position: relative;
    left: 72% !important;
  }
  .tg_pos {
    position: relative;
    left: 62% !important;
  }
}
@media only screen and (max-width: 500px) {
  .tg_position {
    position: relative;
    left: 55% !important;
  }
  .tg_pos {
    position: relative;
    left: 47% !important;
  }
}
@media only screen and (max-width: 450px) {
  .my_gifts_components {
    padding: 5px 8px !important;
  }
  .gift-item {
    padding: 29px 13px 29px 18px !important;
  }
  .tg_position {
    position: relative;
    left: 30% !important;
  }
  .tg_pos {
    position: relative;
    left: 25% !important;
  }

  .recieved-gift-title {
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 390px) {
  .tg_position {
    position: relative;
    left: -9% !important;
  }
  .tg_pos {
    position: relative;
    left: 3% !important;
  }
  .container_pos {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    position: relative;
    left: 64px;
    align-items: center;
  }
  .container_position {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    position: relative;
    left: 64px;
    align-items: center;
  }
}

@media only screen and (max-width: 340px) {
  .container_pos {
    left: 25px !important;
  }
  .container_position {
    left: 25px !important;
  }
}