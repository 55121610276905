.my_payment_history_components {
  padding: 5px 20px !important;
  min-height: 370px;
}
.payment-table {
  float: left;
  width: 100%;
  min-width: 680px !important;
  text-align: center;
}
.payment-table-container {
  width: 100%;
  padding: 15px 0px;
  float: left;
}
.payment-table th {
  background-color: var(--blue);
  padding: 5px 0px;
  font-weight: normal;
  color: #fff;
}
.payment-table td {
  padding: 10px 5px !important;
  color: #646464 !important;
}
.payment-table thead {
  padding: 8px !important;
}
.payment-table td {
  padding: 10px 5px;
  color: #646464 !important;
}
.download_btn {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
  padding: 3px 12px;
  border-radius: 5px;
  -webkit-box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
}
.package-td {
  width: 95px;
}
.pagination-nav {
  float: right !important;
}
.comment-limit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.p-date {
  width: 15%;
}

/* -------------------- responsive -------------------- */

@media only screen and (max-width: 1200px) {
  .payment-table-container {
    overflow: scroll;
    padding: 15px 0px 15px 1px !important;
  }
}
@media only screen and (max-width: 991px) {
  .payment-table-container {
    overflow: scroll;
    padding: 15px 0px 15px 1px !important;
  }
  .p-date {
    width: 17%;
  }
}
@media only screen and (max-width: 768px) {
  .payment-table-container {
    overflow: scroll;
    padding: 15px 0px 15px 1px !important;
  }
  .my_payment_history_components {
    padding: 5px 10px !important;
  }
  .p-date {
    width: 17%;
  }
}
@media only screen and (max-width: 450px) {
  .my_payment_history_components {
    padding: 5px 7px !important;
  }
  .payment-table-container {
    overflow: scroll;
    padding: 15px 0px 15px 5px !important;
  }
}
