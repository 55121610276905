.student_login_img {
  height: 210px !important;
  /* height: 80vh !important; */
}
.student_login_img img {
  width: 85% !important;
}
.custom-row {
  justify-content: center !important;
}

.custom-font {
  font-size: 15px;
}

.w-85 {
  width: 85% !important;
}
.custome-padding-right {
  padding-right: 24px !important;
}
.custome-padding-left {
  padding-left: 24px !important;
}
.select_type_main_card_custome {
  padding: 25px !important;
}

/* @media only screen and (max-width: 1800px) {
  .select_type_main_card_img2 {
    height: 240px !important;
  }
  .select_type_main_card_img1 {
    height: 240px !important;
  }
} */
@media only screen and (min-width: 1400px) {
  .select_type_main_card_img2 {
    height: 240px !important;
  }
  .select_type_main_card_img1 {
    height: 240px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .student_login_img {
    height: 180px !important;
  }
}
@media only screen and (max-width: 768px) {
  .student_login_img {
    height: 165px !important;
  }
  .student_login_img img {
    width: 190px !important;
  }
  .custome-padding-left {
    padding-left: 15px !important;
  }
  .custome-padding-right {
    padding-right: 15px !important;
  }
}
