:root {
  --blue: #005da1;
}
.search_bar {
  float: left;
  position: relative;
  width: 100%;
}
.filter_search_bar_btn {
  float: left;
}

.search_bar input {
  float: left;
  width: 100%;
  background-color: #fff;
  border: 0px;
  padding: 8px 16px;
  border-radius: 5px;
  color: #969696;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.search_bar img {
  position: absolute;
  right: 0px;
  padding: 12px 15px;
  width: 47px;
  color: #969696;
}
/*--------------------------------------------------------student home page scc start --------------------------------------------------------*/

.stdnt_home_main {
  float: left;
  width: 100%;
}
.stdnt_home_main_box {
  float: left;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
}

.course_common_user_profile_txt {
  float: left;
  padding: 0px;
}

.course_common_user_profile_txt p {
  width: 100%;
  float: left;
  margin: 5px 0 0 0;
  padding: 0px;
  color: #323232;
  font-size: 18px;
}
.feed_img_main {
  float: left;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.feed_img_main img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.people_going_main {
  float: right;
}
.people_going_main p {
  width: auto !important;
  margin: 10px 0px 5px 0px;
}

.color_gray {
  color: #646464 !important;
}
.feed_date_time {
  float: left;
  padding: 0px;
  color: #323232;
  font-size: 14px;
  margin: 0px;
}
.feed_date_time img {
  width: 15px;
  margin: 0 6px 5px 0px;
}

.view_more_btn {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
  float: right;
  padding: 5px 18px;
  margin-right: 20px;
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.view_more_btn:hover {
  background-color: transparent;
  color: var(--blue);
  transition: 0.3s ease;
}

@media only screen and (max-width: 1200px) {
  .search_bar {
    width: 100%;
  }
  .search_bar input {
    width: 100%;
  }
}
