:root {
  --blue: #005da1;
}
.class-schedule-table {
  float: left;
  width: 100%;
  text-align: center;
}
.class-schedule-table th {
  background-color: var(--blue);
  padding: 5px 0px;
  font-weight: normal;
  color: #fff;
}
.class-schedule-table thead {
  padding: 8px !important;
}
.class-schedule-table td {
  padding: 5px 0px;
  color: #646464 !important;
}
.status-pending {
  float: left;
  margin: 10px 0px 5px 5px;
  padding: 0px;
  color: #e74c3c;
  font-size: 18px;
  font-weight: bold;
}
.status-paid {
  float: left;
  margin: 10px 0px 5px 5px;
  padding: 0px;
  color: #27ae60;
  font-size: 18px;
  font-weight: bold;
}
.modal-label {
  float: left;
  padding: 0px;
  font-size: 16px;
  color: #323232;
  font-weight: bold;
}
.modal-description {
  float: left;
  padding: 0px;
  font-size: 16px;
  color: #323232;
}
.cust_pad {
  padding: 0 30px !important;
}
.cust_btn_pad {
  padding: 8px 30px !important;
  margin-right: 20px !important;
}
.no-enrollment {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enrollment-comment textarea.form-control {
  box-shadow: none !important;
}
/* textarea:focus {
  border: 1px solid #ced4da !important;
} */
