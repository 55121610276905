.my_leaves_components {
  padding: 5px 20px !important;
  /* min-height: 400px; */
  /* border-bottom: 1px solid #ebecec; */
}
.leave-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.leave-item .leave-title {
  width: 65%;
  float: left;
  padding-right: 10px;
}
.leave-item .leave-balance {
  width: 15%;
  float: left;
  padding: 5px;
}
.leave-item button {
  width: 20%;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: #fff;
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 5px;
  -webkit-box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%);
}
.leave-table {
  float: left;
  width: 100%;
  min-width: 700px !important;
  text-align: center;
}
.leave-table-container {
  width: 100%;
  padding: 15px 0px;
}
.leave-table th {
  background-color: var(--blue);
  padding: 5px 0px;
  font-weight: normal;
  color: #fff;
}
.leave-table td {
  padding: 5px 5px;
  color: #646464 !important;
}
.leave-table thead {
  padding: 8px !important;
}
.leave-table td {
  padding: 10px 5px;
  color: #646464 !important;
}
.w-12 {
  width: 12%;
}
.comment-area {
  box-shadow: 3px 4px 10px 0px rgb(0 0 0 / 5%) !important;
}
@media only screen and (max-width: 1200px) {
  .leave-table-container {
    overflow: scroll;
    padding: 20px 0px;
  }
}
@media only screen and (max-width: 991px) {
  .leave-item .leave-title {
    width: 100%;
  }
  .leave-item .leave-balance {
    width: 60%;
    margin-top: 10px;
    padding-left: 0px;
  }
  .leave-item button {
    margin-top: 10px;
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .leave-item {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .leave-item .leave-title {
    width: 100%;
  }
  .leave-item .leave-balance {
    width: 60%;
    margin-top: 10px;
    padding-left: 0px;
  }
  .leave-item button {
    margin-top: 10px;
    width: 40%;
  }
  .leave-table-container {
    overflow: scroll;
    padding: 20px 0px;
  }
  .leave-table {
    min-width: 700px !important;
  }
}

@media only screen and (max-width: 450px) {
  .my_leaves_components {
    padding: 5px 10px !important;
    /* min-height: 400px; */
  }
  .leave-item {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .leave-item .leave-title {
    width: 100%;
  }
  .leave-item .leave-balance {
    width: 50%;
    padding-left: 0px;
    margin-top: 5px;
  }
  .leave-item button {
    width: 50%;
    margin-top: 5px;
  }
  .leave-table-container {
    overflow: scroll;
    padding: 20px 5px !important;
  }
}
