.left-side-menu {
  float: left;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebecec;
  cursor: pointer;
}
.left-navigation-section {
  height: 400px;
  background: #fff;
  padding-top: 4px;
  border-radius: 7px !important;
}
.left-side-menu img {
  float: left;
  width: 20px;
}
.left-side-menu p {
  float: left;
  padding-left: 15px;
  font-size: 16px;
}
.left-side-menu p:hover {
  color: #005da1 !important;
}
.menu-active {
  color: #005da1 !important;
}

/*  --------------- responsive css ---------------  */
@media only screen and (max-width: 768px) {
  .left-navigation-section {
    height: fit-content;
    margin-bottom: 20px;
  }
}
